.container-description-branding-left {
  .mail-to {
    margin-left: 2rem;
    margin-top: 1rem;
  }
  .lottie-branding-modal {
    width: 100%;
    max-width: 400px;
    margin-top: 3rem;
  }
  .modal-branding-title-left {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 3.75rem;
    line-height: 4.313rem;
    color: $black;
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .modal-branding-description-left {
    font-family: $font-poppins;
    font-style: mixed;
    font-weight: $semi-bold;
    font-size: 1rem;
    line-height: 1.875rem;
    color: $grays;
    text-align: start;
    padding: 0rem 2rem;
    span {
      font-weight: $bold;
    }
    &.span {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
.modal-title-branding-right {
  font-family: $font-alfa;
  font-style: normal;
  font-weight: $semi-bold;
  font-size: 3rem;
  line-height: 3.438rem;
  color: $white;
  margin-left: 3.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.container-modal-branding-description-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .container-modal-branding-description-first {
    padding-left: 2rem;
    .content-description-branding {
      margin: 0rem 1.5rem 0.5rem 1.5rem;
      .modal-branding-description-title {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $bold;
        font-size: 1.5rem;
        line-height: 2.25rem;
        color: $white;
        .modal-branding-count {
          font-size: 3.125rem;
          line-height: 4.313rem;
          margin-right: 1rem;
        }
      }
      .modal-branding-description {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1rem;
        line-height: 1.875rem;
        color: $white;
      }
    }
  }
  .container-modal-branding-description-two {
    padding-right: 5rem;
    .content-description-branding {
      margin-bottom: 0.5rem;
      .modal-branding-description-title {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $bold;
        font-size: 1.5rem;
        line-height: 2.25rem;
        color: $white;
        .modal-branding-count {
          font-size: 3.125rem;
          line-height: 4.313rem;
          margin-right: 1rem;
        }
      }
      .modal-branding-description {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1rem;
        line-height: 1.875rem;
        color: $white;
      }
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .container-description-branding-left {
    .lottie-branding-modal {
      margin-top: 5rem;
    }
    .mail-to {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .modal-title-branding-right {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-left: 2rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
  .container-modal-branding-description-right {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 2rem;
    .container-modal-branding-description-first {
      padding: 1rem 2.5rem 0rem 0.5rem;
      .content-description-branding {
        .modal-branding-description-title {
          .modal-branding-count {
            font-size: 2.5rem;
            line-height: 2.5rem;
          }
        }
      }
    }
    .container-modal-branding-description-two {
      padding: 1rem 2.5rem 0rem 2rem;
      .content-description-branding {
        .modal-branding-description-title {
          .modal-branding-count {
            font-size: 2.5rem;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}