.container-description-social-media-left {
  .lottie-social-media-modal {
    width: 100%;
    max-width: 350px;
    margin-left: 5rem;
  }
  .modal-social-media-title-left {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 3.75rem;
    line-height: 4.313rem;
    color: $black;
    margin-top: -5rem;
    padding: 0rem 2.5rem;
  }
  .modal-social-media-description-left {
    font-family: $font-poppins;
    font-style: mixed;
    font-weight: $semi-bold;
    font-size: 1rem;
    line-height: 1.875rem;
    color: $grays;
    text-align: start;
    padding: 0rem 2.5rem;
    span {
      font-weight: $bold;
    }
    &.span {
      margin-top: 1rem;
    }
  }
}
.modal-title-social-media-right {
  font-family: $font-poppins;
  font-style: normal;
  font-weight: $bold;
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: $white;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.container-modal-social-media-description-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  .container-card-social-media-right {
    text-align: center;
    background-color: $white;
    box-shadow: $rectangle-form-shadow;
    padding: 1rem 1rem;
    .content-card-title-social-media-right {
      font-family: $font-alfa;
      font-style: normal;
      font-weight: $semi-bold;
      font-size: 2rem;
      line-height: 3.438rem;
      color: $black;
    }
    .container-list-social-media {
      padding: 1rem 0rem;
      .list-social-media {
        font-family: $font-poppins;
        font-style: normal;
        font-size: 0.938rem;
        line-height: 2.188rem;
        color: $items-links;
      }
    }
    .btn {
      margin: 0.5rem auto;
    }
  }
  .full {
    margin-right: 0.5rem;
  }
  .bassic {
    margin-right: 0.5rem;
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .container-description-social-media-left {
    padding-bottom: 2rem;
    .lottie-social-media-modal {
      margin-top: 2rem;
    }
  }
  .modal-title-social-media-right {
    margin: 2rem 0rem 2rem 0rem;
  }
  .container-modal-social-media-description-right {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 3rem;
    gap: 2rem;
    height: 100%;
    .container-card-social-media-right {
      padding: 1rem 2rem 1rem 2rem;
    }
    .full {
      margin: 0rem;
    }
    .bassic {
      margin: 0rem;
    }
  }
}