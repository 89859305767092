.card-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 10px 5px #9e9e9e2b;
  background: $white;
  z-index: 10;
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  margin-left: 3rem;
  .card-services-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 70vh;
    min-width: 500px;
    padding: 2rem 2rem;
    .card-services-title {
      font-family: $font-alfa;
      font-style: normal;
      font-weight: $semi-bold;
      font-size: 3rem;
      line-height: 2.8rem;
      color: $black;
      margin-bottom: 0.5rem;
    }
    .card-services-list {
      display: flex;
      flex-direction: row;
      font-family: $font-poppins;
      font-style: normal;
      font-weight: $semi-bold;
      font-size: 1.25rem;
      line-height: 2.5rem;
      color: $items-links;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 100%;
      cursor: pointer;
      .arrow-service {
        width: 1.5rem;
        height: 0.8rem;
        display: inline;
      }

      &:hover {
        animation-name: scaleImg;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0s;
        box-shadow: 0px 0px 10px 5px rgba(158, 158, 158, 0.17);
        background: $white;
        font-weight: $bold;
        color: $blue;
        &::before {
          content: url("../../assets/Icons/arrow-about.svg");
          font-weight: $bold;
          color: $blue;
          padding: 0 1rem;
          margin-top: 0.3rem;
        }
      }
      &.linkActive {
        animation-name: scaleImg;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-delay: 0s;
        box-shadow: 0px 0px 10px 5px rgba(158, 158, 158, 0.17);
        background: $white;
        font-weight: $bold;
        color: $blue;
        &::before {
          content: url("../../assets/Icons/arrow-about.svg");
          font-weight: $bold;
          color: $blue;
          padding: 0 1rem;
          margin-top: 0.3rem;
        }
      }
    }
  }
}

@keyframes scaleImg {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.3);
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .card-services-container {
    margin-top: 4rem;
    margin-left: 4rem;
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .card-services-container {
    margin-top: 5rem;
    padding: 2rem 2rem;
    .card-services-title {
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 1.2rem;
    }
    .card-services-list {
      font-size: 1rem;
      line-height: 3rem;
    }
  }
}
