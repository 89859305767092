.card-service-container-moblie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 10px 5px #9e9e9e2b;
  background: $white;
  margin-top: 7rem;
  max-width: 350px;
  z-index: 10;
  margin-bottom: 3rem;
  padding: 1rem 2rem;
  width: 100%;
  .card-service-title-mobile {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 2.25rem;
    line-height: 2.438rem;
    color: $black;
    margin: 1rem 0rem;
  }
  .card-services-list-mobile {
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 1.25rem;
    line-height: 3.75rem;
    color: $items-links;
    cursor: pointer;
  }

  .card-services-list-mobile-active {
    display: flex;
    align-items: center;
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $bold;
    color: $blue;
    font-size: 1.5rem;
    position: relative;
    margin-bottom: 1rem;
    .arrow-icon-mobile {
      width: 100%;
      max-width: 19px;
      transform: translate(-1rem);
      position: absolute;
      height: 15px;
    }
  }
  .container-card-services {
    margin-top: 3rem;
    margin-bottom: 3rem;
    animation-name: scaleImg;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    box-shadow: 0px 0px 10px 5px rgba(158, 158, 158, 0.17);
    background: $white;
    padding: 1.5rem 1.5rem;
    .container-list-services-mobile-active {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .card-description-services-mobile-active {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $grays;
        margin-bottom: 1rem;
      }
    }
    .marketing-active {
      line-height: 2rem;
      font-size: 1rem;
    }
  }
}
@keyframes scaleImg {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.3);
  }
}