.content-card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5rem;
  padding: 3rem 8rem 0rem 3rem;
  gap: 0.938rem;
  width: 100%;
  .card-title-right {
    @include cardTitleRight;
  }
  .card-description-right {
    @include cardDescriptionRight;
    &.ux-description{
      padding-right: 3rem;
    }
  }
  .content-lottie-ux {
    width: 100%;
    max-width: 240px;
    position: absolute;
    transform: translate(15rem, -12rem);
  }
  .content-lottie-branding {
    width: 100%;
    max-width: 600px;
    position: absolute;
    transform: translate(-1rem, -1rem);
    top: 250px;
    right: 20px;
  }
  .content-lottie-socialMedia {
    position: absolute;
    transform: translate(25rem, -10rem);
  }
  .content-lottie-marketing {
    position: absolute;
    max-width: 350px;
    transform: translate(11rem, -8rem);
  }
}
.dots-right {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 1.8rem;
  right: 2rem;
  .dots-icon-right {
    width: 100%;
    height: 85px;
    img {
      cursor: pointer;
    }
  }
}
.active-right {
  background: $white;
}
@media only screen and (min-width: 421px) and (max-width: 768px){

}