.keen-slider {
  height: 100vh;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &__slide {
    max-width: 100%;
    max-height: 100%;
  }
}
.lottie-content-one {
  width: 100%;
  max-width: 292px;
  transform: translate(20rem, 5rem);
}
.lottie-content-two {
  width: 100%;
  max-width: 300px;
  transform: translate(15rem, 3.5rem);
}
.lottie-content-tree {
  width: 100%;
  max-width: 185px;
  transform: translate(20rem, 7rem);
}
.lottie-content-four {
  width: 100%;
  max-width: 303px;
  transform: translate(17rem, 4rem);
}
.lottie-content-five {
  width: 100%;
  max-width: 322px;
  transform: translate(16rem, 10rem);
}
.lottie-content-six {
  width: 100%;
  max-width: 176px;
  transform: translate(20rem, 6rem);
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .lottie-content-one {
    transform: translate(9rem, 5rem);
  }
  .lottie-content-two {
    transform: translate(13rem, 2rem);
  }
  .lottie-content-tree {
    transform: translate(15rem, 6rem);
  }
  .lottie-content-four {
    transform: translate(10rem, 3rem);
  }
  .lottie-content-five {
    transform: translate(9rem, 7rem);
  }
  .lottie-content-six {
    transform: translate(17rem, 5rem);
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .lottie-content-one {
    transform: translate(4rem, 5rem);
  }
  .lottie-content-two {
    transform: translate(6rem, 2rem);
    max-width: 300px;
  }
  .lottie-content-tree {
    transform: translate(11rem, 5rem);
  }
  .lottie-content-four {
    transform: translate(6.5rem, 4rem);
  }
  .lottie-content-five {
    transform: translate(5rem, 6rem);
  }
  .lottie-content-six {
    transform: translate(10rem, 1.5rem);
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .keen-slider {
    overflow: hidden;
    height: 100%;
    margin-top: 3rem;
    margin-left: 2rem;
    margin-bottom: 6rem;
  }
  .lottie-content-one {
    width: 100%;
    max-width: 292px;
    height: 198px;
    transform: translate(3rem, 5rem);
  }
  .lottie-content-two {
    width: 100%;
    max-width: 250px;
    transform: translate(7rem, 3rem);
  }
  .lottie-content-tree {
    width: 100%;
    max-width: 250px;
    height: 137px;
    transform: translate(4rem, -2rem);
  }
  .lottie-content-four {
    width: 100%;
    max-width: 400px;
    transform: translate(1rem, 2rem);
  }
  .lottie-content-five {
    width: 100%;
    max-width: 400px;
    height: 450px;
    margin-top: 4rem;
    transform: translate(2rem, 8rem);
  }
  .lottie-content-six {
    width: 100%;
    max-width: 300px;
    height: 350px;
    transform: translate(6rem, 8rem);
  }
}