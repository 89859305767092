.form {
  background-color: $white;
  box-shadow: 0px 0px 10px 5px rgba(158, 158, 158, 0.17);
  width: 100%;
  max-width: 545px;
  padding: 2rem;
  z-index: 10;
  margin-left: 7rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  .form-container {
    margin-top: 1rem;
    display: grid;
    gap: 0.5rem;
    
    .form-title {
      font-family: $font-poppins;
      font-style: normal;
      font-weight: $semi-bold;
      font-size: 2rem;
      color: $black-bold;
    }
    .form-group-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      .form-group {
        position: relative;
        color: $items-links;
        .form-input {
          width: 100%;
          cursor: pointer;
          background: none;
          color: $items-links;
          font-size: 1rem;
          font-weight: $semi-bold;
          padding: 0.6em 0.5em;
          border: none;
          outline: none;
          border-bottom: $border-input;
          font-family: $font-poppins;
          &:focus,
          &:not(:placeholder-shown) {
            color: $grays;
            font-style: normal;
            font-weight: $semi-bold;
          }
          &:focus + .form-label,
          &:not(:placeholder-shown) + .form-label {
            transform: translateY(-12px) scale(0.7);
            transform-origin: left top;
            color: $blue;
          }
          &:focus ~ .form-line,
          &:not(:placeholder-shown) ~ .form-line {
            transform: scale(1);
          }
        }
        .form-label {
          color: $items-links;
          position: absolute;
          top: 0;
          left: 5px;
          transform: translateY(3px);
          transition: transform 0.5s, color 0.3s;
        }
        .form-line {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $blue;
          transform: scale(0);
          transform: left bottom;
          transition: transform 0.4s;
        }
      }
    }
    .form-submit {
      font-family: $font-poppins;
      font-style: normal;
      font-weight: $bold;
      font-size: 1.5rem;
      line-height: 2.25rem;
      color: $blue;
      display: flex;
      align-items: flex-start;
      padding-bottom: 0.625rem;
    }
    
    .form-group-select {
      display: flex;
      flex-direction: column;
      width: 100%;
      .form-label-select {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1rem;
        line-height: 3.75rem;
        color: $items-links;
      }
      .form-select {
        appearance: none;
        border: 0;
        outline: 0;
        width: 100%;
        padding: 0 4em 0 1em;
        max-width: 545px;
        cursor: pointer;
        border: $border-input;
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1rem;
        line-height: 2.8rem;
        background: url(../../assets/Icons/arrow-select.svg) no-repeat right
          0.8em center / 0.7rem;
        color: $items-links;
        .form-label-option {
          padding: 0 1.875rem 0 0.625rem;
          background-color: $white;
          border: $border-input;
          outline: $border-input;
          &:focus {
            outline: none;
          }
          &::-ms-expand {
            display: none;
          }
        }
      }
    }
    .form-group-textarea {
      display: flex;
      flex-direction: column;
      width: 100%;
      .form-label-textarea {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1rem;
        line-height: 3.75rem;
        color: $items-links;
      }
      textarea {
        outline: 0;
        border: $border-input;
        width: 100%;
        background: none;
        color: $items-links;
        font-size: 1rem;
        font-weight: $semi-bold;
        border-bottom: $border-input;
        font-family: $font-poppins;
        resize: none;
        height: 75px;
        &:focus,
        &:not(:placeholder-shown) {
          color: $grays;
          font-family: $font-poppins;
          font-style: normal;
          font-weight: $semi-bold;
        }
      }
    }
  }
}
.submit-alert{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  .submit-message{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: $black-bold;
    font-size: 1rem;
    line-height: 1rem;
    color: $grays;
  }
  .icon {
    margin-left: 0.5rem;
    width: 40px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .form {
    padding: 0rem 1rem;
    margin: 0rem;
    transform: translate(6rem, 3.1rem);
    .form-container {
      margin-top: 0.5rem;
      gap: 0rem;
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .form {
    margin-top: 5rem;
    margin-left: 5rem;
    padding: 0rem 1rem;
    .form-container {
      margin-top: 0rem;
      .form-group-container {
        display: grid;
        gap: 0.3rem;
      }
      .form-submit {
        padding: 0rem;
      }
    }
    .form-group-textarea {
      textarea {
        height: 100px;
      }
    }
  }
}
