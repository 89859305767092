.site {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.sitebackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;
  z-index: -1;
}
.container {
  width: 50%;
}

.leftside {
  background: $background-container-grays;
}

.rightside {
  background: $background-container-blue;
  .height-right {
    width: 100%;
    padding-top: 4rem;
  }
  .width-right {
    width: 100%;
  }
}
.content_container {
  max-width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  & > .container {
    width: 50%;
    padding: 3rem 0 0 0;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
  }

  .leftside {
    .content {
      height: 40vh;
    }
    .height-left {
      height: 100%;
      width: 100%;
      margin-top: 1.875rem;
    }
    .width-left {
      width: 100%;
    }
  }
}
.content {
  flex: 1 1 auto;
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .sitebackground {
    display: none;
  }

  .content_container {
    max-width: 100%;
    min-height: 100%;
    .container {
      width: 100%;
      min-height: 100%;
      padding: 0rem;
    }

    .rightside {
      background-color: transparent;
      order: 0;
      .height-right {
        padding-top: 0rem;
        display: flex;
        justify-content: center;
      }
    }
    .leftside {
      order: 1;
      width: 100%;
      height: 100%;
      .height-left {
        margin-top: 0rem;
        padding-top: 0rem;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
