.site-section {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: $background-container-grays;
}
.container-section {
  width: 50%;
}
.content_container-section {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;

  & > .container-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
}
.content-section {
  flex: 1 1 auto;
  padding: 1em;
}

.header-section {
  background: $background-container-blue;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 218px;
  padding: 0.5rem;
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .content_container-section > .container-section {
    padding: 1rem;
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .content_container-section > .container-section {
    padding: 0rem;
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .content_container-section {
    width: 100%;
  }
  .content_container-section > .container-section {
    width: 100%;
    padding: 0rem;
    min-height: 100%;
  }
  .header-section{
    height: 184px;
    padding: 0rem;
  }
}
