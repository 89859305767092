.card-job {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: $white;
  box-shadow: 0px 0px 10px 5px rgba(158, 158, 158, 0.17);
  max-width: 39vw;
  margin-left: 6rem;
  padding: 3rem 3rem;
  z-index: 10;
  margin-top: -9rem;
  .card-job-container-left {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    .card-job-content-left {
      .card-job-join {
        font-size: 2rem;
        line-height: 3.75rem;
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        color: $black-bold;
      }
      .card-job-title {
        font-size: 3rem;
        line-height: 3.438rem;
        font-family: $font-alfa;
        font-style: normal;
        font-weight: $semi-bold;
        color: $black;
      }
      .card-job-lottie-container {
        max-width: 19vw;
        & > div {
          width: 100%;
          height: 100%;
        }
      }
      .card-job-steps {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1.5rem;
        line-height: 2.25rem;
        color: $grays;
        a {
          font-weight: $bold;
        }
      }
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .card-job {
    display: flex;
    flex-direction: column;
    max-width: 150vw;
    margin-top: 7rem;
    margin-left: 1rem;
    padding: 2rem 0rem 2rem 1rem;
    height: 100%;
    margin-right: 1rem;
    position: relative;
    .card-job-container-left {
      .card-job-content-left {
        .card-job-lottie-container {
          max-width: 62vw;
        }
      }
    }
  }
}
