.content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10rem 0rem 0rem 4rem;
  gap: 0.3rem;
  h1 {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 4rem;
    line-height: 4.313rem;
    color: $white;
  }
  p {
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: $white;
  }
  h2 {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 3rem;
    line-height: 4.125rem;
    color: $white;
  }
  .content-right-title-two {
    margin-bottom: 1.2rem;
  }
  .content-right-title-tree {
    margin-top: 0.8rem;
  }
}
.content-down-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5.5rem;
  margin-top: -3rem;
  .arrow-redir {
    cursor: pointer;
    width: 49.69px;
    height: 100%;
    .arrow {
      max-width: 100%;
      height: auto;
    }
  }
}

.content-left {
  max-width: 500px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 4rem;
  & > div{
    width: 100%;
    height: 100%;
  }
}
.logo-home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: -3rem;
  img {
    max-width: 900px;
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .content-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
    gap: 0.3rem;
    padding: 0rem 0rem 0rem 2rem;
    margin: 3rem 0rem 3rem 0rem;
    h1 {
      font-size: 3rem;
      line-height: 3.125rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h2 {
      font-size: 2.25rem;
      line-height: 3.063rem;
    }
  }
  .logo-home {
    margin-left: -2rem;
    margin-top: -0.5rem;
    img {
      max-width: 500px;
    }
  }
  .content-left {
    margin-top: 2rem;
  }
  .content-down-arrow{
    margin-top: -1rem;
    margin-left: -3.5rem;
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .content-left {
    margin-top: -8.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 100%;
  }
  .content-down-arrow {
    width: 100%;
    max-width: 31px;
    height: 63px;
    .arrow-redir {
      transform: translate(-6.5rem, 16rem);
    }
  }
  .content-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
    margin: 7rem 1.5rem 7rem 0rem;
    h1 {
      font-size: 3rem;
      line-height: 3.125rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h2 {
      font-size: 2.25rem;
      line-height: 3.063rem;
    }
  }
  .swipe{
    transition-duration: 500ms !important;
    transform: translateY(50px)!important;
    opacity: 0.5!important;
  }
}
