.container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .dots-mobile {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 2rem;
    top: 4rem;
    gap: 0.5rem;
    padding-bottom: 1rem;
    .dots-icon-mobile {
      width: 100%;
      height: 100%;
      .active-dots-mobile {
        background-color: $white;
      }
      img {
        height: 45px;
      }
    }
  }
  .container-mobile-up {
    min-height: 60vh;
    width: 100%;
    background: $background-container-blue;
    .content-card-mobile {
      width: 100%;
      height: 100%;
      margin-top: 6rem;
      margin-bottom: 6rem;
      padding: 2rem 3rem 0rem 3rem;
      .card-title-mobile {
        font-family: $font-alfa;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 2.9rem;
        line-height: 3.2rem;
        color: $white;
        margin-bottom: 1rem;
      }
      .card-description-mobile {
        font-family: $font-poppins;
        font-style: normal;
        font-weight: $semi-bold;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $white;
        padding: 0rem 2rem 1.5rem 0rem;
        margin-bottom: 1rem;
      }
    }
  }
  .container-mobile-down {
    height: 100%;
    width: 100%;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-lottie-ux-mobile {
      width: 100%;
      max-width: 340px;
      margin-top: -4rem;
    }
    .content-lottie-webApps-mobile {
      width: 100%;
      max-width: 340px;
      margin-top: -7rem;
    }
    .content-lottie-best-websites {
      width: 100%;
      max-width: 450px;
      margin-top: -6rem;
    }
    .content-lottie-branding-mobile {
      width: 100%;
      max-width: 500px;
      margin-top: -3.8rem;
    }
    .content-lottie-socialMedia-mobile {
      width: 100%;
      max-width: 350px;
      margin-top: -7rem;
    }
    .content-lottie-marketing-mobile {
      width: 100%;
      max-width: 350px;
      margin-top: -6rem;
    }
    .content-lottie-app-mobile {
      width: 100%;
      max-width: 350px;
      margin-top: -6rem;
    }
  }
}