.container-description-modal-app-mobile {
  .lottie-modal-app-mobile {
    width: 100%;
    max-width: 200px;
    margin-top: 3rem;
    margin-left: 3rem;
  }
  .modal-app-title-left {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 3.75rem;
    line-height: 4.313rem;
    color: $black;
    text-align: center;
  }
  .modal-app-description-left {
    font-family: $font-poppins;
    font-style: mixed;
    font-weight: $semi-bold;
    font-size: 1rem;
    line-height: 1.875rem;
    color: $grays;
    text-align: start;
    padding: 0.5rem 3rem;
    span {
      font-weight: $bold;
    }
  }
  .btn {
    margin-left: 3rem;
  }
}

.modal-title-app-right {
  font-family: $font-alfa;
  font-style: normal;
  font-weight: $semi-bold;
  font-size: 3rem;
  line-height: 3.438rem;
  color: $white;
  width: 100%;
  max-width: 411px;
  margin-top: 1rem;
}
.container-modal-app-img-first {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
  gap: 7rem;
  padding-top: 3rem;
  .modal-node-logo {
    .app-modal-node-img {
      max-width: 100%;
    }
    p {
      margin-top: 2rem;
    }
  }
  .modal-react-native-logo {
    .app-modal-react-native-img {
      max-width: 100%;
    }
    p {
      white-space: nowrap;
      margin-top: 0.5rem;
    }
  }
  .modal-net-logo {
    .app-modal-net-img {
      max-width: 100%;
    }
    p {
      margin-top: 2rem;
    }
  }
  p {
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-align: center;
    color: $white;
  }
}
.container-modal-app-img-two {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
  gap: 6rem;
  margin-top: 3rem;
  p {
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-align: center;
    color: $white;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 420px) {
  .modal-title-app-right {
    display: none;
  }
  .lottie-modal-app-mobile {
    padding: 0rem;
    margin-top: 5rem;
  }
  .container-description-modal-app-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 2rem;
    .btn {
      margin-left: 0rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .modal-app-title-left {
      font-size: 3rem;
      line-height: 4.313rem;
      margin-bottom: 1rem;
      text-align: start;
    }
    .modal-app-description-left {
      font-size: 1rem;
      line-height: 1.875rem;
      padding: 0.5rem 0rem;
    }
  }
  .container-modal-app-img-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    .modal-react-native-logo {
      .app-modal-react-native-img {
        max-width: 100%;
      }
      p {
        margin-top: 1rem;
      }
    }
    .modal-node-logo {
      .app-modal-node-img {
        max-width: 100%;
      }
      p {
        margin-top: 1rem;
      }
    }
    .modal-net-logo {
      p {
        margin-top: 1rem;
      }
    }
  }
  .container-modal-app-img-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    gap: 4rem;
    .content-modal-app-ios {
      p {
        margin-top: 1rem;
      }
    }
    .content-modal-app-android {
      p {
        margin-top: 1rem;
      }
    }
    .content-modal-app-aws {
      p {
        margin-top: 1rem;
      }
    }
    .content-modal-app-firebase {
      p {
        margin-top: 1rem;
      }
    }
  }
}