@media only screen and (min-width: 0px) and (max-width: 420px) {
    .form{
        width: 100%;
        height: 100%;
        padding: 0rem 2rem 1rem 2rem;
        max-width: 330px;
        transform: none;
        margin:7rem 0rem 3rem 0rem;
        margin-bottom: 7rem;
        .form-container{
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .contact-content-right{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 0 auto;
                padding: 0rem 1.5rem 0rem 0rem;
                .contact-lottie-right{
                    position: relative;
                    transform: translate(-4rem);
                }
                .contact-title-right{
                    font-size: 2.25rem;
                    line-height: 2.438rem;
                }
                .contact-description-right{
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }
            .form-title{
                font-size: 2rem;
                line-height: 4.313rem;
            }
            .form-group-container{
                display: grid;
                grid-template-columns: 1fr;
                .form-group{
                    .form-input{
                        font-size: 1rem;
                    }
                    .form-label{
                        font-size: 1rem;
                    }
                }
            }
            .form-submit{
                font-size: 1.5rem;
                line-height: 2.25rem;
            }
            .form-group-textarea{
                .form-label-textarea{
                    line-height: 2rem;
                    margin-bottom: 1rem;
                    margin-top: 0.5rem;
                }
                textarea{
                    height: 100px;
                }
            }
        }
        
    }
    .social-media-container-mobile-contact{
        position: absolute;
        gap: 1rem;
        bottom: 1rem;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
    .submit-alert{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        .submit-message{
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: $black-bold;
          font-size: 1rem;
          line-height: 1rem;
          color: $grays;
        }
        .icon {
          margin-left: 0.5rem;
          width: 40px;
        }
      }
}