.contact-content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -3rem;
  margin-top: 8rem;
  padding: 1rem;
  .contact-lottie-right {
    width: 100%;
    height: 7.5;
    position: absolute;
    transform: translate(13rem, -6rem);
  }
  .contact-title-right {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 3rem;
    line-height: 3.438rem;
    color: $black;
    margin-bottom: 1rem;
  }
  .contact-description-right {
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: $grays;
    span {
      font-weight: $bold;
    }
  }
  .social-media-container-mobile-contact-desktop{
    display: flex;
    flex-direction: row;
    padding: 2rem 0rem;
    gap: 1rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .contact-content-right{
    margin-top: 18rem;
    margin-left: 4rem;
    .contact-lottie-right {
      max-width: 250px;
      transform: translate(14rem, -5rem);
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .contact-content-right {
    margin-left: 0rem;
    padding: 2rem;
    margin-top: 13rem;
    .contact-lottie-right {
      transform: translate(1rem, -9rem);
      max-width: 200px;
    }
    .contact-title-right {
      font-size: 2rem;
      line-height: 2rem;
    }
    .contact-description-right {
      font-size: 1rem;
    }
  }
}
