.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  .header-container {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    .header-container {
      width: 100%;
      height: auto;
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    .header-container {
      width: 100%;
      height: auto;
    }
  }
}
