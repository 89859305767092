.modal-site {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    width: 0px;
  }
}
.modal {
  display: none;
}
.is-open {
  display: flex;
}
.modal-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $background-container-grays;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;
  z-index: -1;
}
.content-container-modal {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  .header-modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
  }
}
.container-leftside-modal {
  width: 35%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  .content-modal-leftside {
    display: flex;
    flex-direction: column;
  }
}
.container-rightside-modal {
  width: 65%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $background-container-blue;
  .content-modal-rightside {
    display: flex;
    flex-direction: column;
  }
}
.close-modal {
  width: 30px;
  height: 96px;
  font-weight: 300;
  font-size: 4rem;
  line-height: 6rem;
  color: $white;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 3.5rem;
  cursor: pointer;
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .content-container-modal {
    .header-modal {
      margin-left: -2rem;
    }
  }
  .content-container-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
  }
  .container-leftside-modal {
    width: 100%;
    height: 100%;
    min-height: 100%;
    order: 0;
    display: flex;
    flex-direction: column;
  }
  .container-rightside-modal {
    order: 1;
    width: 100%;
    height: 100%;
    min-height: 100%;
    .content-modal-rightside{
      display: flex;
      flex-direction: column;
      max-width: 100% ;
    }
  }
  .close-modal {
    top: 0rem;
    right: 0rem;
    color: $blue;
    font-size: 2rem;
    margin-top: -1rem;
  }
  .modal-background{
    display: none;
  }
}
