.icon-contact {
  position: fixed;
  max-width: 40px;
  height: 45px;
  right: 5rem;
  bottom: 5rem;
  z-index: 999;
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .icon-contact{
    right: 4rem;
    bottom: 2rem;
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .icon-contact {
    right: 2rem;
    bottom: 2rem;
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .icon-contact {
    right: 3rem;
    bottom: 2rem;
  }
}
