.background-header-about {
  background: $background-container-blue;
  height: 100px;
  width: 100%;
}
.content-left-about {
  display: flex;
  flex-direction: column;
  margin-left: 3.125rem;
  padding: 0rem 5rem 0rem 5rem;
  margin-top: -4rem;
  .title-left-about {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 6rem;
    line-height: 5.625rem;
    color: $black;
    margin-bottom: 1.5rem;
  }
  .about {
    margin-bottom: 1.5rem;
  }
  .about-m {
    margin-top: 1rem;
  }
  .description-left-about {
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: $grays;
    .span-left-about {
      font-weight: $bold;
    }
  }
}
.keen-slider {
  width: 100%;
  height: auto;
}
.slide-content {
  display: flex;
  flex-direction: column;
  flex: none;
  padding: 0rem 1rem 0rem 1rem;
  margin-top: -5rem;
  .lottie-content {
    width: 500px;
    height: 150px;
    transform: translate(6.25rem);
    .lottie-slide {
      width: 100%;
      height: auto;
    }
  }
  .slide-count {
    color: $blue;
    font-family: $font-alfa;
    font-weight: $semi-bold;
    font-style: normal;
    font-size: 4rem;
    line-height: 4.313rem;
    padding-top: 0.625rem;
  }
  .slide-title {
    font-family: $font-alfa;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 4rem;
    line-height: 3.438rem;
    color: $black;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .slide-description {
    font-family: $font-poppins;
    font-style: normal;
    font-weight: $semi-bold;
    font-size: 1rem;
    line-height: 1.875rem;
    padding: 0rem 13rem 0rem 0rem;
    margin-right: 6rem;
    color: $grays;
    span {
      font-weight: $bold;
    }
  }
  &.first {
    height: 650px;
  }
  &.secod {
    height: 650px;
  }
  &.third {
    height: 510px;
  }
  &.fourth {
    height: 580px;
  }
  &.fifth {
    height: 600px;
  }
  &.sixth {
    height: 650px;
    margin-bottom: 5rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .content-left-about {
    padding: 0rem;
    margin-top: 0rem;
    .description-left-about {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .title-left-about {
      margin-bottom: 1rem;
      font-size: 5rem;
      line-height: 5rem;
    }
    .about {
      margin-bottom: 1rem;
    }
  }
  .slide-content {
    .slide-description {
      padding: 0rem;
      margin: 0rem;
    }
    .slide-title {
      font-size: 3rem;
      line-height: 2rem;
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 768px) {
  .content-left-about {
    padding: 0rem 3rem 0rem 3rem;
    margin: 5rem auto;
    .title-left-about {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  .slide-content {
    .slide-count {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
    .slide-title {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
    .slide-description {
      padding: 0rem;
      margin: 0 auto;
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 420px) {
  .background-header-about {
    height: 500px;
  }
  .content-left-about {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    margin-top: -25rem;
    padding: 0rem 2rem 0rem 0rem;
    margin-left: 3rem;
    .title-left-about {
      font-size: 3rem;
      line-height: 3.125rem;
      color: $white;
      margin-bottom: 1rem;
      padding-right: 4.5rem;
    }
    .description-left-about {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $white;
    }
    .about {
      margin-bottom: 1rem;
    }
    .about-m {
      margin-top: 1rem;
    }
  }
  .slide-content.sixth{
    height: 100vh;
  }
  .slide-content {
    height: 550px;
    padding: 0rem 2.5rem 0rem 1rem;
    .slide-count {
      font-size: 3rem;
      line-height: 4.313rem;
    }
    .slide-title {
      font-size: 2.25rem;
      line-height: 2.375rem;
    }
    .slide-description {
      font-size: 1rem;
      line-height: 1.875rem;
      margin: 0rem;
      padding: 0rem;
    }
  }
}
