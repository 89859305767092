.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 0.5rem;
  padding-right: 1rem;
  background: transparent;
  z-index: 998;
  .hamburguer-icon {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    img {
      width: 40px;
    }
  }
  .hamburguer-icon-close {
    border: 0;
    height: 96px;
    width: 40px;
    padding: 0.5rem;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .navigation-menu {
    margin-left: auto;
    ul {
      display: flex;
      padding: 0;
      li {
        margin: 0 1rem;
        .link-a {
          display: block;
          width: 100%;
          font-family: $font-poppins;
          font-style: normal;
          font-weight: $semi-bold;
          font-size: 1.25rem;
          line-height: 1.375rem;
          color: $white;
        }
      }
      .social-media-container {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .navigation {
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 800;
    top: 0;
    left: 0;
    padding: 0rem;
    .hamburguer-icon {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      margin: 2rem 2rem 0rem 0rem;
      z-index: 5;
    }
    .hamburguer-icon-close {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      margin: 2rem 2rem 0rem 0rem;
      z-index: 5;
    }
    .navigation-menu.expanded {
      display: block;
      ul {
        display: none;
      }
    }
    .navigation-menu {
      margin: 0 auto;
      width: 100%;
      opacity: 0.9;
      height: 100%;
      ul {
        width: 100%;
        margin: 0 auto;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 998;
        background: $background-container-blue;
        li {
          text-align: center;
          margin: 0;
          .active {
            width: 100%;
            font-family: $font-poppins;
            font-style: normal;
            font-weight: $bold;
            font-size: 2.25rem;
            line-height: 3.75rem;
            color: $white;
          }
          .link-a {
            width: 100%;
            padding: 1;
            font-family: $font-poppins;
            font-style: normal;
            font-weight: $semi-bold;
            font-size: 2.25rem;
            line-height: 3.75rem;
            text-align: center;
            color: $white;
          }
        }
        .social-media-container {
          display: flex;
          justify-content: space-around;
          transform: translate(0rem, 5rem);
          .social-media {
            max-width: 32px;
            height: 32px;
            margin: 0.5rem;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}
.active {
  width: 100%;
  font-family: $font-poppins;
  font-style: normal;
  font-weight: $bold;
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: $white;
}
